
import { Vue } from "vue-class-component";
import debounce from "lodash/debounce";
import { CommodityController } from "@/app/ui/controllers/CommodityController";
import {
  checkRolePermission,
  isUrlUpdateVersion
} from "@/app/infrastructures/misc/Utils";
import { PRICING_COMMODITY } from "@/app/infrastructures/misc/RolePermission";
import { Commodity } from "@/domain/entities/Commodity";

export default class CommodityList extends Vue {
  mounted() {
    CommodityController.initListCommodities();
  }
  get getCurrentRoute() {
    return this.$route.meta.title;
  }

  // select status
  statusData = [
    { name: "Semua Status", value: "" },
    { name: "Active", value: "active" },
    { name: "Inactive", value: "inactive" }
  ];
  statusSelect = false;
  statusName = "";
  onOpenStatusSelect() {
    this.statusSelect = true;
  }
  onCloseStatusSelect() {
    this.statusSelect = false;
  }
  onSelectStatus(name: string, value: string) {
    CommodityController.setStatus(value);
    CommodityController.setListToFirstPage();
    this.onRequest();
    this.statusName = name;
  }

  //Filter Search
  onSearch = debounce((value: string) => {
    CommodityController.setSearch(value);
    CommodityController.setListToFirstPage();
    this.onRequest();
  }, 250);
  get searchValue() {
    return CommodityController.search;
  }
  clearSearch() {
    CommodityController.setSearch("");
    this.onRequest();
  }

  // table
  columns = [
    {
      name: "ID",
      key: "id",
      styleHead: "w-20 text-center whitespace-no-wrap",
      styleBody: "text-red-lp-200 text-center",
      render: (item: any) => {
        return `<span class='text-center text-red-lp-200 flex justify-center'>${item.commodity_id}</span>`;
      }
    },
    {
      name: "Kode komoditas",
      styleHead: "w-40 text-left whitespace-no-wrap",
      styleBody: "",
      render: (item: any) => {
        return `<span class="whitespace-no-wrap">
              ${item.commodity_code !== "" ? item.commodity_code : "-"}</span>`;
      }
    },
    {
      name: "Nama komoditas",
      key: "name",
      styleHead: "w-40 text-left",
      styleBody: "text-left rounded py-0",
      render: (item: any) => {
        return `<div class='pr-8'>${item.commodity_name || "-"}</div>`;
      }
    },
    {
      name: "Dangerous Goods",
      styleHead: "w-40 text-left",
      render: (item: Commodity) => {
        return `${`<img src=${require(`@/app/ui/assets/svg/${
          item.isDangerousGoods ? "check-circle.svg" : "close-circle.svg"
        }`)}`} width="24" height="24" />`;
      }
    },
    {
      name: "Commodity name",
      key: "name",
      styleHead: "w-40 text-left",
      styleBody: "text-left rounded py-0",
      render: (item: any) => {
        return `<div class='pr-8'>${item.commodity_name_en.trim() || "-"}</div>`;
      }
    },
    {
      name: "Komoditas eCargo",
      key: "eCargoCommodity",
      styleHead: "w-72 text-left",
      render: (item: any) => {
        return `<div class='text-black-lp-200 flex justify-start'><div class='rounded px-2 py-0 bg-gray-lp-400'>${
          item.commodity_group !== "" ? item.commodity_group : "-"
        }</div></div>`;
      }
    },
    {
      name: "Kode HS",
      key: "hsCode",
      styleHead: "w-40 text-left",
      styleBody: "text-left rounded py-0",
      render: (item: any) => {
        return `<div>${item.hs_code !== "" ? item.hs_code : "-"}</div>`;
      }
    },
    {
      name: "Status Komoditas",
      key: "status",
      styleHead: "w-40 text-left",
      styleBody: "",
      render: (item: any) => {
        return `
        <div class="flex">
      <div
        class="rounded-full px-2 py-0 ${
          item.commodity_status === "active" ? "bg-green-lp-300" : "bg-red-300"
        } ${
          item.commodity_status === "active"
            ? "text-green-lp-200"
            : "text-red-lp-200"
        }"
      ><span class="capitalize">${
        item.commodity_status === "active" ? "Active" : "Inactive"
      }</span></div></div>`;
      }
    }
  ];
  get commodities() {
    return CommodityController.commodities.data;
  }
  get pagination() {
    return CommodityController.commodities.pagination;
  }
  get isLoading() {
    return CommodityController.isLoading;
  }
  get isError() {
    return CommodityController.isError;
  }
  get errorCause() {
    return CommodityController.errorCause;
  }
  get paginationStyle() {
    return isUrlUpdateVersion("commodity") === "v1" ? "v1" : "v3";
  }
  onRequest() {
    CommodityController.fetchListCommodities();
  }

  // detail
  get isDetailAble() {
    return checkRolePermission(PRICING_COMMODITY.DETAIL);
  }
  onClickRow(item: any) {
    this.$router.push(`/master-data/commodity/${item.commodity_id}`);
  }
}
