import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-6" }
const _hoisted_2 = { class: "flex flex-row gap-6" }
const _hoisted_3 = { class: "flex flex-row gap-6" }
const _hoisted_4 = { class: "flex flex-row gap-6" }
const _hoisted_5 = { class: "flex flex-row gap-6" }
const _hoisted_6 = { class: "flex flex-row gap-6" }
const _hoisted_7 = { class: "flex flex-row gap-6" }
const _hoisted_8 = { class: "flex flex-row gap-6" }
const _hoisted_9 = { class: "flex flex-col gap-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_ChipsV2 = _resolveComponent("ChipsV2")!
  const _component_LpButton = _resolveComponent("LpButton")!
  const _component_DetailLayout = _resolveComponent("DetailLayout")!

  return (_openBlock(), _createBlock(_component_DetailLayout, {
    title: 
      `${_ctx.detailCommodity.commodity_name} - ${_ctx.detailCommodity.commodity_code}`
    ,
    onBack: _ctx.goBack,
    errorCause: _ctx.errorCause,
    error: !!_ctx.errorCause,
    loading: _ctx.isLoading,
    onTryAgain: _ctx.fetchDetailCommodity
  }, {
    data: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, [
          _createVNode(_component_DataWrapper, {
            class: "flex-1",
            label: "ID Komoditas",
            fontSize: 14,
            value: _ctx.detailCommodity.commodity_id
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            class: "flex-1",
            label: "Kode Komoditas",
            fontSize: 14,
            value: _ctx.detailCommodity.commodity_code
          }, null, 8, ["value"])
        ]),
        _createVNode("div", _hoisted_3, [
          _createVNode(_component_DataWrapper, {
            class: "flex-1",
            label: "Nama Komoditas",
            fontSize: 14,
            value: _ctx.detailCommodity.commodity_name
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            class: "flex-1",
            label: "Commodity Name",
            fontSize: 14,
            value: _ctx.detailCommodity.commodity_name_en
          }, null, 8, ["value"])
        ]),
        _createVNode("div", _hoisted_4, [
          _createVNode(_component_DataWrapper, {
            class: "flex-1",
            label: "Deskripsi",
            fontSize: 14,
            value: _ctx.detailCommodity.description
          }, null, 8, ["value"])
        ]),
        _createVNode("div", _hoisted_5, [
          _createVNode(_component_DataWrapper, {
            class: "flex-1",
            label: "Komoditas eCargo",
            fontSize: 14,
            value: _ctx.detailCommodity.commodity_group
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            class: "flex-1",
            label: "Tipe Layanan",
            fontSize: 14,
            value: _ctx.detailCommodity.commodity_service_type
          }, null, 8, ["value"])
        ]),
        _createVNode("div", _hoisted_6, [
          _createVNode(_component_DataWrapper, {
            class: "flex-1",
            label: "Harmonized system code",
            fontSize: 14,
            value: _ctx.detailCommodity.hs_code
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            class: "flex-1",
            label: "Min. harga barang",
            fontSize: 14,
            value: _ctx.formatPrice(_ctx.detailCommodity.commodity_min_price)
          }, null, 8, ["value"])
        ]),
        _createVNode("div", _hoisted_7, [
          _createVNode(_component_DataWrapper, {
            class: "flex-1",
            label: "Dangerous Goods",
            fontSize: 14,
            value: _ctx.detailCommodity.isDangerousGoods ? 'Ya' : 'Tidak'
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            class: "flex-1",
            label: "Dokumen surcharge",
            fontSize: 14,
            value: 
              _ctx.detailCommodity.commodity_document_surcharge === 'yes'
                ? 'Ya'
                : 'Tidak'
            
          }, null, 8, ["value"])
        ]),
        _createVNode("div", _hoisted_8, [
          _createVNode(_component_DataWrapper, {
            class: "flex-1",
            label: "Surcharge",
            fontSize: 14,
            value: 
              _ctx.detailCommodity.commodity_surcharge_applicable === 'yes'
                ? 'Ya'
                : 'Tidak'
            
          }, null, 8, ["value"])
        ])
      ])
    ]),
    "sub-data": _withCtx(() => [
      _createVNode("div", _hoisted_9, [
        _createVNode(_component_DataWrapper, {
          label: "Status Komoditas",
          fontSize: 14
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ChipsV2, {
              class: "my-2",
              label: _ctx.detailCommodity.commodity_status
            }, null, 8, ["label"])
          ]),
          _: 1
        }),
        _createVNode(_component_DataWrapper, {
          label: "Tanggal dibuat",
          value: _ctx.formatDate(_ctx.detailCommodity.created_at)
        }, null, 8, ["value"]),
        _createVNode(_component_DataWrapper, {
          label: "Terakhir diperbarui",
          value: _ctx.formatDate(_ctx.detailCommodity.updated_at),
          caption: _ctx.detailCommodity.updated_by
        }, null, 8, ["value", "caption"]),
        (_ctx.isEditAble)
          ? (_openBlock(), _createBlock(_component_LpButton, {
              key: 0,
              title: "Ubah",
              onClick: _ctx.goEdit,
              "custom-class": "w-36",
              textColor: "white"
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["title", "onBack", "errorCause", "error", "loading", "onTryAgain"]))
}