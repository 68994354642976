
import { Options, Vue } from "vue-class-component";
import { CommodityController } from "@/app/ui/controllers/CommodityController";
import {
  dateToDateString,
  dateToTime,
  checkRolePermission,
  formatPrice
} from "@/app/infrastructures/misc/Utils";
import { PRICING_COMMODITY } from "@/app/infrastructures/misc/RolePermission";

@Options({
  components: {},
  computed: {
    isEditAble() {
      return checkRolePermission(PRICING_COMMODITY.EDIT);
    }
  }
})
export default class Detail extends Vue {
  formatPrice = formatPrice
  mounted() {
    this.getDetailCommodity(this.$route.params.id);
  }

  getDetailCommodity(id: any) {
    return CommodityController.getCommodityDetail({ id });
  }

  fetchDetailCommodity() {
    this.getDetailCommodity(this.$route.params.id);
  }

  get isLoading() {
    return CommodityController.isLoadingDetail;
  }

  get detailCommodity() {
    return CommodityController.commodity;
  }

  get minimumPriceView() {
    return CommodityController.minimumPriceView;
  }

  get isError() {
    return CommodityController.isError;
  }

  get errorCause() {
    return CommodityController.errorCause;
  }

  formatDate(date: string) {
    return `${dateToDateString(new Date(date))}, ${dateToTime(
      new Date(date)
    )} WIB`;
  }

  goBack() {
    this.$router.push("/master-data/commodity");
  }
  goEdit() {
    this.$router.push(`/master-data/commodity/${this.$route.params.id}/edit`);
  }
}
